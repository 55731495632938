// src/app/components/delivery-tracking/delivery-tracking.component.ts
import { Component, OnInit } from '@angular/core';
import { DeliveryTrackingService, ShipmentData } from '../services/delivery-tracking.service';

@Component({
  selector: 'app-delivery-tracking',
  templateUrl: './delivery-tracking.component.html',
  styleUrls: ['./delivery-tracking.component.css']
})
export class DeliveryTrackingComponent /*implements OnInit*/ {

  shipmentData:any;
  errorMessage: string = '';

  waybill: string = ''; // User input for waybill number
  constructor(private deliveryTrackingService: DeliveryTrackingService) {}


  fetchShipmentData() {
    if (!this.waybill) {
      alert('Please enter a waybill number');
      return;
    }
    console.log("called ");
    this.deliveryTrackingService.getTrackingData(this.waybill).subscribe(
      (data) => {
        this.shipmentData = data[0].Shipment;
        console.log(this.shipmentData);
      },
      (error) => {
        console.error('Error fetching shipment data', error);
        alert('Failed to fetch shipment data. Please try again.');
      }
    );
  }

  
  // ngOnInit(): void {
  //   this.deliveryTrackingService.getTrackingData().subscribe(
  //     data => {
  //       this.shipmentData = data;
  //       console.log('Shipment Data:', this.shipmentData);
  //     },
  //     error => {
  //       this.errorMessage = 'Failed to load shipment data';
  //       console.error(error);
  //     }
  //   );
  // }
}
