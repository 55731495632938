<section id="blog" class="blog-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-10">
        <div class="section-title text-center">
          <h2>Latest Blog Posts</h2>
          <p>Butter also has a built in blog engine which makes it dead simple
            to launch a new company blog.
          </p>
          <p>
            <a
              href="blog"
              class="main-btn btn-hover mt-5">View All Blog Posts
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <ng-container *ngFor="let post of posts.data.data">
        <div class="col-lg-4 col-md-8 col-sm-10">
          <div class="single-blog">
            <div class="blog-header" *ngIf="post.featured_image">
              <img
                class="img-fluid"
                loading="lazy"
                [src]="post.featured_image"
                [alt]="post.featured_image_alt"
              >
            </div>
            <div class="blog-body">
              <h5 class="package-name"><a href="#">{{post.title}}</a></h5>
              <div className="single-post-body" [innerHTML]="post.summary"></div>
            </div>
            <div class="blog-footer">
              <a (click)="onClick(post.slug)" class="main-btn btn-hover">Read More</a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>
