<!-- ========================= preloader start ========================= -->
<div class="preloader">
  <div class="loader">
    <div class="spinner">
      <div class="spinner-container">
        <div class="spinner-rotator">
          <div class="spinner-left">
            <div class="spinner-circle"></div>
          </div>
          <div class="spinner-right">
            <div class="spinner-circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- preloader end -->
