// support-info.component.ts
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-info',
  templateUrl: './support-info.component.html',
  styleUrls: ['./support-info.component.css']
})
export class SupportInfoComponent implements OnInit {
  mainWarehouses: number = 0;
  fieldExecutives: number = 0;
  countriesCovered: number = 0;

  private targetValues = {
    mainWarehouses: 27, // Example target values
    fieldExecutives: 335,
    countriesCovered: 180
  };

  private hasAnimated = false;

  constructor() {}

  ngOnInit(): void {}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const element = document.querySelector('.support-info-container');
    if (element) {
      const rect = element.getBoundingClientRect();
      if (rect.top < window.innerHeight && !this.hasAnimated) {
        this.hasAnimated = true;
        this.animateCounters();
      }
    }
  }

  animateCounters() {
    this.animateCounter('mainWarehouses', this.targetValues.mainWarehouses);
    this.animateCounter('fieldExecutives', this.targetValues.fieldExecutives);
    this.animateCounter('countriesCovered', this.targetValues.countriesCovered);
  }

  animateCounter(counter: string, targetValue: number) {
    let current = 0;
    const interval = setInterval(() => {
      if (current < targetValue) {
        current++;
        this[counter] = current;
      } else {
        clearInterval(interval);
      }
    }, 10); // Adjust this value for faster/slower animation
  }
}
