import {NgModule} from '@angular/core';
import {IMPORTS, EXPORTS, SERVICES, COMPONENTS} from './index';
import { HeroSlideshowComponent } from './components/hero-slideshow/hero-slideshow.component';
import { InfoSectionComponent } from './components/info-section/info-section.component';
import { ParallaxComponent } from './components/parallax/parallax.component';
import { InfoSectionImgComponent } from './components/info-section-img/info-section-img.component';
import { MilestoneTimelineComponent } from './components/milestone-timeline/milestone-timeline.component';
import { HeroBannerComponent } from './components/hero-banner/hero-banner.component';
import { SupportInfoComponent } from './components/support-info/support-info.component';
import { DeliveryTrackingComponent } from './components/delivery-tracking/delivery-tracking.component';
import { PartnersComponent } from './components/partners/partners.component';
import { FaqComponent } from './components/faq/faq.component';
import { AlertComponent } from './components/alert/alert.component';
import { DeliveryCostComponent } from './components/delivery-cost/delivery-cost.component';
import { FloatingButtonComponent } from './components/floating-button/floating-button.component';
import { AboutUsComponent } from './components/about-us/about-us.component';

@NgModule({
  imports: [
    ...IMPORTS
  ],
  declarations: [
    ...COMPONENTS,
    HeroSlideshowComponent,
    InfoSectionComponent,
    ParallaxComponent,
    InfoSectionImgComponent,
    MilestoneTimelineComponent,
    HeroBannerComponent,
    SupportInfoComponent,
    DeliveryTrackingComponent,
    PartnersComponent,
    FaqComponent,
    AlertComponent,
    DeliveryCostComponent,
    FloatingButtonComponent,
    AboutUsComponent
  ],
  providers: [
    ...SERVICES
  ],
  exports: [
    ...EXPORTS
  ]
})
export class LayoutModule {
}
