
    <app-header
      [menuData]="menuData"
      (showMenu)="showMenudata($event)"
    >
    </app-header>
    <!-- <ng-container *ngIf="isApiToken(); else noApiToken">
     
    </ng-container>
    <ng-template #noApiToken> -->
      <!-- <ng-template> -->
        <!-- <app-hero-slideshow></app-hero-slideshow> -->
         <app-hero-banner *ngIf="showmenuVal === 'home' || showmenuVal === ''"></app-hero-banner>
        <app-about-us *ngIf="showmenuVal === 'aboutus'"></app-about-us>
        <app-faq *ngIf="showmenuVal === 'faq'"></app-faq>
        <app-delivery-cost *ngIf="showmenuVal === 'costc'"></app-delivery-cost>
        <app-info-section-img *ngIf="showmenuVal === 'home' || showmenuVal === ''" (onClick)="handleImageClick()"></app-info-section-img>

        <!-- <app-info-section></app-info-section> -->
        <app-parallax></app-parallax>
        <!-- <app-milestone-timeline></app-milestone-timeline> -->
         <app-support-info></app-support-info>
         <app-partners></app-partners>
         <!-- <app-delivery-tracking></app-delivery-tracking> -->

      <!-- </ng-template> -->

      <!-- <app-no-api-token></app-no-api-token> -->
    <!-- </ng-template> -->
    <app-footer
      [menuData]="menuData"
    >
    </app-footer>

    <!-- <router-outlet></router-outlet> npm run build-->