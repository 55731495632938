<div class="waybill-input">
    <input
      type="text"
      [(ngModel)]="waybill"
      placeholder="Enter Waybill Number"
    />
    <button (click)="fetchShipmentData()">Track</button>
  </div>
  
  <div class="timeline" *ngIf="shipmentData">
    <div class="shipment-status">
      <h2>Shipment Status</h2>
      <p>Current Status: {{ shipmentData.Shipment.Status.Status }}</p>
      <p>Location: {{ shipmentData.Shipment.Status.StatusLocation }}</p>
    </div>
  
    <div *ngFor="let scan of shipmentData.Shipment.Scans" class="timeline-item">
      <div
        class="timeline-status"
        [ngClass]="getStatusClass(scan.ScanDetail.Scan)"
      ></div>
      <div class="timeline-content">
        <p>
          <strong>{{ scan.ScanDetail.Scan }}</strong> - {{ scan.ScanDetail.Instructions }}
        </p>
        <p>Date: {{ scan.ScanDetail.ScanDateTime | date: 'medium' }}</p>
        <p>Location: {{ scan.ScanDetail.ScannedLocation }}</p>
      </div>
    </div>
  </div>
  