import { Component } from '@angular/core';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent {
  partners = [
    { name: 'D-Mart', image: './assets/images/partners/dmart.jpg' },
    { name: 'HCL-Tech', image: './assets/images/partners/hcltech.jpg' },
    { name: 'JSW Cements', image: './assets/images/partners/jsw.png' },
    { name: 'Ministry of Defence India', image: './assets/images/partners/mdi.jpg' },
    { name: 'NeoSoft Tech', image: './assets/images/partners/neosoft.png' },
    { name: 'Paras Aerospace', image: './assets/images/partners/parasaerospace.jpg' },
    { name: 'Park +', image: './assets/images/partners/parkplus.jpg' },
    { name: 'Reliance Retails', image: './assets/images/partners/reliance.jpg' },
    { name: 'Sony India', image: './assets/images/partners/sony.png' },
    { name: 'Bombay Play', image: './assets/images/partners/bomplay.png' },
    { name: 'Bharat Petrolium', image: './assets/images/partners/bpi.png' },
    { name: 'Cummins India', image: './assets/images/partners/cummins.png' },
    { name: 'Fine Organics', image: './assets/images/partners/fineorg.jpg' },
    { name: 'Happy Tabs', image: './assets/images/partners/happytabs.jpg' },
    { name: 'HCura Homeopathy', image: './assets/images/partners/hcura.jpg' },
    { name: 'Ingersoll Rand India', image: './assets/images/partners/iri.png' },
    { name: 'JCB India', image: './assets/images/partners/jcb.png' },
    { name: 'Johnson Controls India', image: './assets/images/partners/jci.png' },
    { name: '91 Social', image: './assets/images/partners/nos.jpg' },



    







    
    // Add more partners here
  ];
}
