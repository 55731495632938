<!-- <footer class="footer pt-120">
  <div class="container">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
        <div class="footer-widget">
          <div class="logo">
            <a href="https://buttercms.com">
              <img width="200" src="https://cdn.buttercms.com/PBral0NQGmmFzV0uG7Q6" alt="logo" loading="lazy">
            </a>
          </div>
          <p class="desc">ButterCMS is your content backend. Build better with Butter.</p>
          <ul class="social-links">
            <li><a href="#0"><i class="lni lni-facebook"></i></a></li>
            <li><a href="#0"><i class="lni lni-linkedin"></i></a></li>
            <li><a href="#0"><i class="lni lni-instagram"></i></a></li>
            <li><a href="#0"><i class="lni lni-twitter"></i></a></li>
          </ul>
        </div>
      </div>
      <div class="col-xl-5 col-lg-4 col-md-12 col-sm-12 offset-xl-1">
        <div class="footer-widget">
          <h3>About Us</h3>
          <ul class="links">
            <ng-container *ngFor="let item of menuItems">
              <li><a class="page-scroll active" href="{{item.url}}">{{item.label}}</a></li>
            </ng-container>
          </ul>
        </div>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="footer-widget">
          <h3>Subscribe Newsletter</h3>
          <form action="#">
            <input type="email" placeholder="Email">
            <button class="main-btn btn-hover">Subscribe</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</footer> -->

<footer class="footer-background">
  <!--  class="footer " -->
  <!-- <div class="footer-background"></div> -->
  <div class="footer-content">
    <div class="footer-section contact-info">
      <h3>Contact Us</h3>
      <p><strong>Head Office:</strong>8 & 9, Gaurav Anand, Eastern Express Highway, Thane East 400603 Maharashtra</p>
      <p><strong>Regional Office:</strong> 3, 60/1, 15th Main Rd, HAL 2nd Stage, Indiranagar, Bengaluru, 560008 Karnataka</p>
      <p><strong>Phone:</strong> +91-9987940280, +91-9167355974, +91-9821429678, +91-9967259996</p>
      <p><strong>Email:</strong> <a href="mailto:support@xpressdeilvery.com">support@xpressdeilvery.com</a></p>
      <p><strong>Business Hours:</strong></p>
      <p>Mon - Fri: 9:00 AM - 5:00 PM</p>
      <p>Sat: 10:00 AM - 2:00 PM</p>
      <p>Sun: Closed</p>
    </div>


      <div class="footer-section contact-info">
        <h3>More branches</h3>
        <p><strong>Regional office:</strong>Office no 1, Near Nagarpalika, Sangaria, Rajasthan 335063</p>
        <p><strong>Regional Office:</strong> Shop no 14, Sadguru Shopping center, Opp BMC school, Bhandup west, Mumbai 400078</p>
        <p><strong>Phone:</strong> +91-9987940280, +91-9167355974, +91-9821429678, +91-9967259996</p>
        <p><strong>Email:</strong> <a href="mailto:support@xpressdeilvery.com">support@xpressdeilvery.com</a></p>
        <p><strong>Business Hours:</strong></p>
        <p>Mon - Fri: 9:00 AM - 5:00 PM</p>
        <p>Sat: 10:00 AM - 2:00 PM</p>
        <p>Sun: Closed</p>
      </div>

    <!-- <div class="footer-section privacy-policy">
      <h3>Privacy</h3>
      <p><a href="/privacy-policy" class="privacy-link">Privacy Policy</a></p>
    </div> -->
    <!-- <div class="footer-section social-media">
      <h3>Follow Us</h3>
      <p>
        <a href="https://facebook.com/yourcompany" target="_blank" class="social-link">Facebook</a> |
        <a href="https://twitter.com/yourcompany" target="_blank" class="social-link">Twitter</a> |
        <a href="https://linkedin.com/company/yourcompany" target="_blank" class="social-link">LinkedIn</a> |
        <a href="https://instagram.com/yourcompany" target="_blank" class="social-link">Instagram</a>
      </p>
    </div> -->
    <!-- <div class="footer-section about-us">
      <h3>Why Xpressdelivery ?</h3>
      <p>
        Managing logistics internally can be costly and complex. Hiring a team to handle it means higher expenses and often a lack of the specialized expertise needed to navigate the logistics industry effectively. But why take on that burden when you can have an entire organization working for you at a fraction of the cost?
        
        At Xpress Delivery, you don’t just get a service; you get the collective power of a whole team of logistics experts. Whether you're shipping sensitive, fragile items, time-critical deliveries, or coordinating in rural areas, we handle it with precision and care. Our extensive reach and seamless operations bring you everything under one roof, ensuring top-notch results without the need to tie up with several vendors.
        
        But that’s not all—we’re also here for your personal shipping needs. Here’s why Xpress Delivery is the perfect partner for individual customers:</p>
    </div> -->
    <section class="footer-section about-us">
      <h3>Why Xpressdelivery ?</h3>
      <ul>
        <li><strong>Cost-Effective:</strong> Outsourcing logistics to Xpress Delivery reduces the high costs of managing an internal team.</li>
        <li><strong>Expertise:</strong> Gain access to logistics specialists without developing in-house knowledge.</li>
        <li><strong>Comprehensive Service:</strong> Handle everything from fragile shipments to rural deliveries with one provider.</li>
        <li><strong>Seamless Operations:</strong> Avoid dealing with multiple vendors—Xpress Delivery covers all logistics needs.</li>
        <li><strong>Personal Shipping:</strong> We also offer tailored solutions for individual customers.</li>
      </ul>
    </section>
  </div>
  <div class="footer-bottom">
    <p>&copy; 2024 xpressdeilvery. All rights reserved.
       <!-- <a href="/privacy-policy" class="privacy-link">Privacy Policy </a> -->
       |<a href="https://www.facebook.com/people/Xpress-Delivery/61561216652563/" target="_blank" class="social-link">&nbsp;Facebook</a> |
      <a href="https://www.instagram.com/xpressdelivery._/" target="_blank" class="social-link">Instagram</a> |
      <a href="https://www.linkedin.com/company/xpress-delivery-uniwde-xpress-and-shipping-pvt-ltd" target="_blank" class="social-link">LinkedIn</a> |
    </p>

  </div>
</footer>

<app-scroll-top></app-scroll-top>

<!-- =================== JS All Templates ========================= -->
<!-- <script src="assets/js/bootstrap.min.js"></script>
<script src="assets/js/polyfill.js"></script>
<script src="assets/js/main.js"></script> -->

<!-- ================= JS Index HTML only ========================= -->
<!-- <script src="assets/js/tiny-slider.js"></script>
<script src="assets/js/client-slider.js"></script>
<script src="assets/js/section-menu.js"></script> -->
