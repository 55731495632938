
import { Component, OnInit, HostListener, Renderer2, ElementRef } from '@angular/core';

@Component({
  selector: 'app-hero-slideshow',
  templateUrl: './hero-slideshow.component.html',
  styleUrls: ['./hero-slideshow.component.css']
})
export class HeroSlideshowComponent implements OnInit {
  slides = [
    { image: 'https://wallpapercave.com/wp/wp3704690.png', caption: 'Caption 1' },
    { image: 'https://wallpapercave.com/wp/wp3704693.jpg', caption: 'Caption 2' },
    { image: 'https://wallpapercave.com/wp/wp3704705.jpg', caption: 'Caption 3' }
  ];
  currentIndex = 0;

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void {
    this.startAutoSlide();
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const scroll = window.pageYOffset;
    this.renderer.setStyle(this.el.nativeElement.querySelector('.slideshow-container'), '--scroll', `${scroll * 0.5}px`);
  }

  prevSlide(): void {
    const currentSlide = this.el.nativeElement.querySelector(`.slide:nth-child(${this.currentIndex + 1})`);
    currentSlide.classList.add('outgoing');

    this.currentIndex = (this.currentIndex > 0) ? this.currentIndex - 1 : this.slides.length - 1;

    const nextSlide = this.el.nativeElement.querySelector(`.slide:nth-child(${this.currentIndex + 1})`);
    nextSlide.classList.add('incoming');

    setTimeout(() => {
      currentSlide.classList.remove('active', 'outgoing');
      nextSlide.classList.remove('incoming');
      nextSlide.classList.add('active');
    }, 1000);
  }

  nextSlide(): void {
    const currentSlide = this.el.nativeElement.querySelector(`.slide:nth-child(${this.currentIndex + 1})`);
    currentSlide.classList.add('outgoing');

    this.currentIndex = (this.currentIndex < this.slides.length - 1) ? this.currentIndex + 1 : 0;

    const nextSlide = this.el.nativeElement.querySelector(`.slide:nth-child(${this.currentIndex + 1})`);
    nextSlide.classList.add('incoming');

    setTimeout(() => {
      currentSlide.classList.remove('active', 'outgoing');
      nextSlide.classList.remove('incoming');
      nextSlide.classList.add('active');
    }, 1000);
  }

  startAutoSlide(): void {
    setInterval(() => {
      this.nextSlide();
    }, 5000); // Change slide every 5 seconds
  }
}
