<!-- src/app/milestone-timeline/milestone-timeline.component.html -->
<div class="timeline-container">
    <!-- <button class="scroll-button left" (click)="scrollTimeline(-200)">&lt;</button> -->
    <div class="timeline">
      <div *ngFor="let milestone of milestones" class="milestone">
        <div class="content">
          <div class="year">{{ milestone.year }}</div>
          <div class="icon-container">
            <mat-icon class="icon">{{ milestone.icon }}</mat-icon>
          </div>
          <div class="description">{{ milestone.description }}</div>
        </div>
        <div class="arrow" *ngIf="milestone !== milestones[milestones.length - 1]"></div>
      </div>
    </div>
    <!-- <button class="scroll-button right" (click)="scrollTimeline(200)">&gt;</button> -->
  </div>
  