<div class="info-section">
    <h2>Explore Our Features</h2>
    <div class="info-cards">
      <div class="info-card">
        <h3>Local / Domestic Courier Services</h3>
        <p>We ensure prompt and reliable delivery of parcels and documents across the country, with real-time tracking and proof of delivery.</p>
        <button>Learn More</button>
      </div>
      <div class="info-card">
        <h3>International Courier Services</h3>
        <p>From important documents to personal belongings, we provide hassle-free international shipping services with customs clearance assistance.</p>
        <button>Learn More</button>
      </div>
      <div class="info-card">
        <h3>Cargo Services</h3>
        <p>Efficient handling and transportation of large shipments and freight, with customized solutions for businesses and individuals</p>
        <button>Learn More</button>
      </div>
      <div class="info-card">
        <h3>Extra Baggage Services</h3>
        <p>Seamlessly transport excess luggage or belongings worldwide, ensuring they reach the destination securely and on time.</p>
        <button>Learn More</button>
      </div>
      <div class="info-card">
        <h3>B2C & E-commerce Shipping Services</h3>
        <p>Facilitating seamless shipping solutions for businesses and integration with e-commerce platforms, enhancing customer satisfaction with timely deliveries.</p>
        <button>Learn More</button>
      </div>
      <div class="info-card">
        <h3>B2B Logistic Solutions & Shipping</h3>
        <p>Optimized logistics and streamlined import/export services to enhance supply chain efficiency and facilitate smooth international trade.</p>
        <button>Learn More</button>
      </div>
    </div>
  </div>
  