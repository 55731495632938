import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent {
  @Input() title: string = 'Alert';
  @Input() message: string = 'This is an alert message.';
  @Input() isTrackInfo: boolean = false
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  tid: string = '';
  trackClicked = false

  private readonly whatsappNumber: string = '9740585405';
  private readonly whatsappMessage: string = 'Hello, I would like to make an inquiry!';
  visible: boolean = true;

  closeAlert() {
    this.visible = false;
    this.onClose.emit();
  }
  openWhatsapp(){
    const encodedMessage = encodeURIComponent(this.whatsappMessage);
    const url = `https://wa.me/${this.whatsappNumber}?text=${encodedMessage}`;
    window.open(url, '_blank');
  }
  track(){
    this.trackClicked = true
  }

  okay() {
    this.visible = false;
    this.onClose.emit();
  }
}
/*
<app-alert 
  *ngIf="showAlert" 
  [title]="'Alert Title'" 
  [message]="'This is a custom alert message!'"
  (onClose)="handleAlertClose()">
</app-alert>
<button (click)="showAlert = true">Show Alert</button>
*/

/*
showAlert: boolean = false;

  handleAlertClose() {
    this.showAlert = false;
  }
    */

