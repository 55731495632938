<div class="partners-section">
  <h2>Our Partners</h2>
  <div class="marquee">
    <div class="marquee-content">
      <div *ngFor="let partner of partners" class="partner-item">
        <img [src]="partner.image" [alt]="partner.name" />
        <p>{{ partner.name }}</p>
      </div>
      <!-- Duplicating the content for the seamless loop -->
      <div *ngFor="let partner of partners" class="partner-item">
        <img [src]="partner.image" [alt]="partner.name" />
        <p>{{ partner.name }}</p>
      </div>
    </div>
  </div>
</div>
