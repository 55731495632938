<!-- <div class="parallax">
    <div class="parallax-background"></div>
    <div class="parallax-content">
      <h2>Stunning Scenery</h2>
      <p>Experience the beauty of our world with this immersive parallax effect.</p>
    </div>
  </div>
   -->

   <div class="parallax2"></div>
   
   <div class="parallax-content">
   <p>
    Discover seamless shipping with Xpress Delivery! Whether you're a business, E-Commerce seller, or sending packages to loved ones, we're your trusted partner. Our fast, reliable service ensures your packages arrive safely and on time.

    We offer personalized solutions for every need, from express deliveries to bulk shipments and international shipping. Trust Xpress Delivery for convenience, reliability, and top-notch service. Start your shipping adventure with us today!
    </p>
   </div>
   <div class="parallax2"></div>
