<section [id]="fields.scroll_anchor_id"  class="feature-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-10">
        <div class="section-title mb-60">
          <h2 class="mb-20">{{fields.headline}}</h2>
          <p>{{fields.subheadline}}</p>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row">
          <ng-container *ngFor="let feature of fields.features">
            <div class="col-lg-6 col-md-6">
              <div class="single-feature">
                <div class="feature-icon">
                  <img [src]="feature.icon" alt="" />
                </div>
                <div class="feature-content">
                  <h4>{{feature.headline}}</h4>
                  <p>{{feature.description}}</p>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
