<header class="header">
  <div #navbarArea id="" class="navbar-area" [class.sticky]="isSticky">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" href="">
              <img src="./assets/logo.png" alt="Logo" />
            </a>
            <button [ngClass]="{'navbar-toggler': true, 'active': isTogglerActive}" (click)="onTogglerClick()" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="toggler-icon"></span>
              <span class="toggler-icon"></span>
              <span class="toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse sub-menu-bar" [class.show]="isTogglerActive" id="navbarSupportedContent">
              <div class="ms-auto">
                <ul id="nav" class="navbar-nav ms-auto">
                  <ng-container *ngFor="let item of menuItems">
                    <li class="nav-item" (click)="onMenuItemClick(item)">
                      <a class="page-scroll" href="#" [class.active]="isActive(['/' + item.url])" [routerLinkActive]="['active']" >{{item.label}}</a>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div> <!-- navbar collapse -->

          </nav> <!-- navbar -->
        </div>
      </div> <!-- row -->
    </div> <!-- container -->
  </div> <!-- navbar area -->
</header>
