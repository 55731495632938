import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-parallax',
  templateUrl: './parallax.component.html',
  styleUrls: ['./parallax.component.css']
})
export class ParallaxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const parallaxBackground = document.querySelector('.parallax-background') as HTMLElement;
    if (parallaxBackground) {
      const offset = window.pageYOffset;
      parallaxBackground.style.transform = `translateY(${offset * 0.5}px)`; // Adjust speed as needed
    }
  }
}
