<div class="hero-banner">
    <div class="hero-content">
      <h1 class="fade-in">Welcome to Xpressdelivery</h1>
      <p class="fade-in delay-1">We provide the best courier service.</p>
      <div class="buttons fade-in delay-2">
        <button class="track-info" (click)="onTrackInfo()">Track Info</button>
        <button class="book-courier" (click)="onBookCourier()">Book Courier</button>
      </div>
    </div>
  </div>

  <app-alert 
  *ngIf="showAlert" 
  [title]="title" 
  [message]="message"
  [isTrackInfo] = "isTrackInfo"
  (onClose)="handleAlertClose()">
</app-alert>
  