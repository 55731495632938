import { Component } from '@angular/core';

interface FAQ {
  question: string;
  answer: string;
  showAnswer: boolean;
}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent {
  faqs: FAQ[] = [
    {
      question: '1. What services does Xpress Delivery offer?',
      answer: 'Xpress Delivery provides comprehensive courier and cargo services, including domestic and international shipping, on-demand pickup, complex packing, customs clearance, and specialized solutions for industries like pharma, chemicals, and more.',
      showAnswer: false
    },
    {
      question: '2. How can I track my shipment with Xpress Delivery?',
      answer: 'You can easily track your shipment through our online tracking tool. Simply enter your tracking number on our Track Your Shipment page to get real-time updates on your package’s status.',
      showAnswer: false
    },
    {
      question: '3. Does Xpress Delivery offer same-day or next-day delivery services?',
      answer: 'Yes, Xpress Delivery offers both same-day and next-day delivery options for urgent shipments. These services are available in select regions; please contact our customer support for more details.',
      showAnswer: false
    },
    {
      question: '4. How does Xpress Delivery handle fragile or sensitive items?',
      answer: 'We use specialized packing materials and techniques, including wooden crates for fragile items, to ensure the safe transport of sensitive goods. Our team is trained to handle delicate items with the utmost care.',
      showAnswer: false
    },
    {
      question: '5. What areas does Xpress Delivery cover?',
      answer: 'Xpress Delivery covers over 32,000 pin codes across India and delivers to 190 countries worldwide. Our extensive network allows us to provide efficient and reliable service wherever you need.',
      showAnswer: false
    },
    {
      question: '6. How do I schedule a pickup with Xpress Delivery?',
      answer: 'Scheduling a pickup is simple. Visit our Schedule a Pickup page or call our customer service to arrange a convenient time. We offer flexible pickup options to meet your needs.',
      showAnswer: false
    },
    {
      question: '7. What are Xpress Delivery’s rates for international shipping?',
      answer: 'Our international shipping rates are competitive and vary based on the destination, package weight, and service level. You can use our Rate Calculator for a detailed quote or contact us for customized pricing.',
      showAnswer: false
    },
    {
      question: '8. Does Xpress Delivery provide insurance for shipments?',
      answer: 'Yes, we offer shipment insurance to protect your goods during transit. You can choose the insurance coverage that best suits your needs at the time of booking.',
      showAnswer: false
    },
    {
      question: '9. What should I do if my shipment is delayed or lost?',
      answer: 'If your shipment is delayed or lost, please contact our customer support immediately. We will initiate an investigation and work to resolve the issue as quickly as possible.',
      showAnswer: false
    },
    {
      question: '10. How can I make a payment for my shipment?',
      answer: 'We offer multiple payment options, including credit/debit cards, net banking, and digital wallets. Payments can be made online through our secure payment gateway during the booking process.',
      showAnswer: false
    },
    {
      question: '11. Does Xpress Delivery handle customs clearance for international shipments?',
      answer: 'Yes, Xpress Delivery provides full customs clearance services for international shipments. Our experienced team ensures that your packages comply with all regulatory requirements.',
      showAnswer: false
    },
    {
      question: '12. Can Xpress Delivery handle large or bulk shipments?',
      answer: 'Absolutely! Xpress Delivery is equipped to handle large and bulk shipments, including heavy machinery, industrial parts, and other oversized items. Contact us for customized solutions and pricing.',
      showAnswer: false
    },
    {
      question: '13. What are the packaging guidelines for shipping with Xpress Delivery?',
      answer: 'Proper packaging is crucial for safe transit. We recommend using sturdy boxes, proper cushioning, and sealing all packages securely. For detailed guidelines, visit our Packaging Tips page.',
      showAnswer: false
    },
    {
      question: '14. How do I contact Xpress Delivery’s customer service?',
      answer: 'You can reach our customer service team via phone at +91-9167355974, email at support@xpressdelivery.in, or through our Contact Us page. We’re here to assist you with any inquiries.',
      showAnswer: false
    },
    {
      answer: 'Certain items are prohibited for shipping due to legal or safety reasons. Please refer to our Prohibited Items List for more information before sending your package.',
      question: '15. What is Xpress Delivery’s policy on prohibited items?',
      showAnswer: false
    },
    {
      question: '16. Does Xpress Delivery offer discounts for bulk shipping or corporate accounts?',
      answer: 'Yes, we offer discounts for bulk shipments and corporate accounts. Contact us to learn more about our special rates and how we can support your business needs.',
      showAnswer: false
    },
    {
      question: '17. What should I do if I need to cancel or modify my shipment?',
      answer: 'To cancel or modify a shipment, please contact our customer service as soon as possible. We will assist you with the necessary changes, subject to our cancellation policy.',
      showAnswer: false
    },
    {
      question: '18. How can I become a partner or agent for Xpress Delivery?',
      answer: 'If you’re interested in becoming a partner or agent, please visit our Partnership Opportunities page for more information and to apply.',
      showAnswer: false
    },
    
    // Add more FAQs here
  ];

  toggleAnswer(faq: FAQ) {
    faq.showAnswer = !faq.showAnswer;
  }
}


