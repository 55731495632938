import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-info-section-img',
  templateUrl: './info-section-img.component.html',
  styleUrls: ['./info-section-img.component.css']
})
export class InfoSectionImgComponent {
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();



  clicked(){
    this.onClick.emit();
  }
}
