import {Routes, RouterModule} from '@angular/router';
import {NotFoundComponent} from './pages/not-found/components';
import { DeliveryTrackingComponent } from './layout/components/delivery-tracking/delivery-tracking.component';
import { FaqComponent } from './layout/components/faq/faq.component';
import { DeliveryCostComponent } from './layout/components/delivery-cost/delivery-cost.component';
import { AboutUsComponent } from './layout/components/about-us/about-us.component';

const mainRoutes: Routes = [
  {path: '404', component: NotFoundComponent},
  {path:'track', redirectTo:'/track'},
  {path:'track',component: DeliveryTrackingComponent},
  {path:'faq', redirectTo:'/faq'},
  {path:'faq',component: FaqComponent},
  {path:'cost', redirectTo:'/cost'},
  {path:'cost',component: DeliveryCostComponent},
  {path:'aboutus', redirectTo:'/aboutus'},
  {path:'aboutus',component: AboutUsComponent},
  //{path: '**', redirectTo: '/404'},
];

export const appRouting = RouterModule.forRoot(mainRoutes);
