import { Component } from '@angular/core';

@Component({
  selector: 'app-delivery-cost',
  templateUrl: './delivery-cost.component.html',
  styleUrls: ['./delivery-cost.component.css']
})
export class DeliveryCostComponent {
  sourcePinCode: string = '';
  destinationPinCode: string = '';
  weight: number = 0;
  calculatedCost: number | null = null;
  showBreakdown: boolean = false;
  regionRate: number = 0;
  baseRate: number = 100;

  calculateCost() {
    if (this.sourcePinCode && this.destinationPinCode && this.weight > 0) {
      this.regionRate = this.getRegionRate(this.sourcePinCode, this.destinationPinCode);
      const gst = 0.18;

      const charge = this.baseRate + (this.regionRate * Math.ceil(this.weight));
      this.calculatedCost = charge + (charge * gst);
    } else {
      this.calculatedCost = null;
    }
  }

  getRegionRate(source: string, destination: string): number {
    if (source === destination) {
      return 35; // Local
    } else if(this.isSpecialZone(destination)){
      return 80;
    }else if (this.isSameRegion(source, destination)) {
      return 50; // Regional
    } else {
      return 70; // Interstate
    }
  }

  isSpecialZone(destination: string): boolean{
    if(parseInt(destination) >= 791001 && parseInt(destination) <= 792131)//Arunachal Pradesh
      return true
    else if(parseInt(destination) >= 797001 && parseInt(destination) <= 798627)//Nagaland
      return true
    else if(parseInt(destination) >= 799001 && parseInt(destination) <= 799290)//Tripura
      return true
    else if(parseInt(destination) >= 744101 && parseInt(destination) <= 744304)//Andaman
      return true
    else if(parseInt(destination) >= 396210 && parseInt(destination) <= 396240)//Dadra
      return true  
    else if(parseInt(destination) >= 682551 && parseInt(destination) <= 682559)//Lakshadweep
      return true    
    else if(parseInt(destination) >= 180001 && parseInt(destination) <= 194404)//Jammu
      return true  
    else if(parseInt(destination) >= 194101 && parseInt(destination) <= 194404)//Ladakh
      return true  
    else if(parseInt(destination) >= 781001 && parseInt(destination) <= 788931)//Aasam
      return true  
    else if(parseInt(destination) >= 793001 && parseInt(destination) <= 794115)//Meghalaya
      return true  
    else if(parseInt(destination) >= 796001 && parseInt(destination) <= 796891)//Mizoram
      return true  
    else if(parseInt(destination) >= 248001 && parseInt(destination) <= 249205)//Uttarakhand
      return true  
    else if(parseInt(destination) >= 751001 && parseInt(destination) <= 770077)//Odisha
      return true  
    else if(parseInt(destination) >= 795001 && parseInt(destination) <= 795159)//Manipur
      return true  
    else if(parseInt(destination) >= 737101 && parseInt(destination) <= 737139)//Sikkim
      return true  
    else if(parseInt(destination) >= 700001 && parseInt(destination) <= 743711)//West bengal
      return true  
    else 
      return false
  }
  isSameRegion(source: string, destination: string): boolean {
    return source.substring(0, 2) === destination.substring(0, 2);
  }

  toggleBreakdown() {
    this.showBreakdown = !this.showBreakdown;
  }

  getWeigt(){
    return Math.ceil(this.weight);
  }
}
