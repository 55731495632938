import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Milestone } from 'src/app/models/milestone.model';

@Component({
  selector: 'app-milestone-timeline',
  templateUrl: './milestone-timeline.component.html',
  styleUrls: ['./milestone-timeline.component.css']
})
export class MilestoneTimelineComponent implements OnInit {
  @ViewChild('timeline') timeline!: ElementRef;

  milestones: Milestone[] = [
    { year: '2010', description: 'Company founded', icon: 'business' },
    { year: '2012', description: 'Reached 100 customers', icon: 'people' },
    { year: '2015', description: 'Launched new product line', icon: 'new_releases' },
    { year: '2018', description: 'Expanded to international markets', icon: 'public' },
    { year: '2021', description: 'Acquired by a larger company', icon: 'corporate_fare' },
    { year: '2023', description: 'Hit 1 million customers', icon: 'emoji_events' }
  ];

  constructor() { }

  ngOnInit(): void { }

  scrollTimeline(amount: number) {
    this.timeline.nativeElement.scrollBy({ left: amount, behavior: 'smooth' });
  }
}
