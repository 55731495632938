import {AppComponent} from './app.component';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {appRouting} from './app.routing';
import {MatIconModule} from '@angular/material/icon'
import {LayoutModule} from './layout/layout.module';
import {NotFoundModule} from './pages/not-found/not-found.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';



export const COMPONENTS = [
  AppComponent
];

export const IMPORTS = [
  BrowserModule,
  HttpClientModule,
  FormsModule,
  BrowserAnimationsModule,
  appRouting,
  MatIconModule,
  LayoutModule,
  NotFoundModule
];

export const SERVICES = [];
