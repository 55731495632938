import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-no-api-token',
  templateUrl: './no-api-token.component.html'
})
export class NoApiTokenComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
