<div class="slideshow-container">
  <div *ngFor="let slide of slides; let i = index" class="slide" [ngClass]="{'active': i === currentIndex}">
    <a [routerLink]="slide.route">
      <img [src]="slide.image" [alt]="slide.caption">
      <div class="caption">{{ slide.caption }}</div>
    </a>
  </div>

  <a class="prev" (click)="prevSlide()">&#10094;</a>
  <a class="next" (click)="nextSlide()">&#10095;</a>
</div>
