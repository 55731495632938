<div class="delivery-cost-container">
    <div class="background-overlay">
      <h2>Calculate Delivery Cost</h2>
      <form (ngSubmit)="calculateCost()">
        <div class="form-group">
          <label for="sourcePinCode">Source Pin Code:</label>
          <input id="sourcePinCode" [(ngModel)]="sourcePinCode" name="sourcePinCode" type="text" required>
        </div>
        <div class="form-group">
          <label for="destinationPinCode">Destination Pin Code:</label>
          <input id="destinationPinCode" [(ngModel)]="destinationPinCode" name="destinationPinCode" type="text" required>
        </div>
        <div class="form-group">
          <label for="weight">Weight (kg):</label>
          <input id="weight" [(ngModel)]="weight" name="weight" type="number" min="0" required>
        </div>
        <button type="submit">Calculate</button>
      </form>
      <div *ngIf="calculatedCost !== null" class="result">
        <h3>
          Delivery Cost: ₹{{ calculatedCost | number:'1.2-2' }}
          <span class="info-icon" (click)="toggleBreakdown()"> (i) </span>
        </h3>
        <div *ngIf="showBreakdown" class="breakdown">
          <p>Base Rate: ₹100</p>
          <p>Region Rate: ₹{{ regionRate | number:'1.2-2' }} per kg</p>
          <p>Weight: {{ getWeigt() }} kg</p>
          <p>Subtotal: ₹{{ baseRate + (regionRate * weight) | number:'1.2-2' }}</p>
          <p>GST (18%): ₹{{ (baseRate + (regionRate * weight)) * 0.18 | number:'1.2-2' }}</p>
          <p>Total Cost: ₹{{ calculatedCost | number:'1.2-2' }}</p>
        </div>
      </div>
    </div>
  </div>
  