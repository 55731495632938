import { Component } from '@angular/core';

@Component({
  selector: 'app-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.css']
})
export class HeroBannerComponent {
  showAlert =false;
  title = '';
  message = '';
  isTrackInfo = false;
  onTrackInfo() {
    // Logic for tracking info
    // alert('Track Info Clicked');
    this.title = 'Track Info';
    this.message = 'Your Package is still in transit, Please contact us!'
    this.showAlert = true;
    this.isTrackInfo = true

  }

  onBookCourier() {
    // Logic for booking courier
    this.title ='Book Courier';
    this.message = "Call or chat at 9740585405 instant for booking of your package"
    this.showAlert = true;
    this.isTrackInfo = false;
  }

  handleAlertClose(){
    this.showAlert = false;
  }
}
