<!-- support-info.component.html -->
<div class="support-info-container">
    <div class="overlay">
      <div class="info-section">
        <h2>We Support Full Assistance in Freight and Warehousing</h2>
        <div class="counters">
          <div class="counter">
            <span class="number" [innerText]="mainWarehouses"></span><span class="plus-sign">+</span>
            <p>Main Warehouses</p>
          </div>
          <div class="counter">
            <span class="number" [innerText]="fieldExecutives"></span><span class="plus-sign">+</span>
            <p>Field Executives</p>
          </div>
          <div class="counter">
            <span class="number" [innerText]="countriesCovered"></span><span class="plus-sign">+</span>
            <p>Countries Covered</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  