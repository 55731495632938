<div class="about-us-container">
    <section class="about-us-section">
      <h1>About Us</h1>
      <p>
        Step into a world of seamless shipping solutions with Xpress Delivery! Whether you're a business owner, a busy professional, an E-Commerce seller, or an individual sending packages to loved ones, we're here to make your shipping experience as effortless as possible.
      </p>
      <p>
        At Xpress Delivery, we're more than just a courier service – we're your trusted shipping partner. With our lightning-fast, reliable services, we'll get your packages where they need to go, safely and on time, every single time.
      </p>
      <p>
        But that's not all. We understand that every shipment is unique, which is why we offer a range of personalized solutions tailored to your specific needs. From express deliveries to bulk shipments, fragile items to international shipping, we've got you covered.
      </p>
      <p>
        So whether you're shipping across town or across the globe, trust Xpress Delivery to make it happen. Join us on a journey of convenience, reliability, and unparalleled service. Your shipping adventure starts here!
      </p>
    </section>
  
    <section class="what-we-do-section">
      <h2>What We Do?</h2>
      <div class="service">
        <h3>Domestic Courier Services</h3>
        <p>Our domestic courier services ensure swift and secure delivery of parcels and documents within the country. Whether it's urgent documents or essential items, we guarantee timely delivery to your doorstep. With a widespread network and advanced tracking system, we keep you informed about the status of your shipment from pickup to delivery.</p>
      </div>
      <div class="service">
        <h3>International Courier Services for Household</h3>
        <p>Our international courier services cater to households, offering seamless shipping solutions for personal belongings, documents, and more. We understand the importance of reliable overseas delivery, which is why we provide end-to-end assistance, including customs clearance and tracking, to ensure your shipments reach their destination safely and on time.</p>
      </div>
      <div class="service">
        <h3>Cargo Services</h3>
        <p>Our cargo services are designed to handle large shipments and freight with efficiency and reliability. Whether it's commercial cargo or personal belongings, our experienced team ensures safe transportation and timely delivery. From packaging to customs clearance, we manage the entire logistics process to provide a hassle-free experience for our clients.</p>
      </div>
      <div class="service">
        <h3>Extra Baggage Services</h3>
        <p>Traveling with excess luggage can be stressful, but our extra baggage services offer a convenient solution. Whether you're relocating or just carrying extra belongings, we provide reliable transportation for your additional baggage. With our door-to-door service and efficient handling, you can travel light and worry-free.</p>
      </div>
      <div class="service">
        <h3>B2B Logistic Solutions</h3>
        <p>Our B2B logistic solutions are tailored to meet the unique needs of businesses, optimizing supply chains for maximum efficiency. From inventory management to distribution, we offer comprehensive logistics services to streamline operations and reduce costs. With our expertise and personalized approach, we help businesses achieve their goals with ease.</p>
      </div>
      <div class="service">
        <h3>B2C & E-commerce Shipping Services</h3>
        <p>Our B2C and e-commerce shipping services are designed to meet the growing demands of online businesses. Whether you're a small retailer or a large e-commerce platform, we provide reliable shipping solutions to ensure customer satisfaction. With our fast and efficient delivery network, you can focus on growing your business while we take care of the logistics.</p>
      </div>
      <div class="service">
        <h3>Import & Export (Shipping Services)</h3>
        <p>Our import and export shipping services facilitate smooth international trade operations, ensuring seamless transportation of goods across borders. Whether it's imports or exports, we handle the entire logistics process, including customs clearance and documentation, to ensure compliance and timely delivery. With our expertise in global logistics, you can trust us to optimize your supply chain and facilitate international transactions.</p>
      </div>
    </section>
  
    <section class="terms-section">
      <h2>Terms & Conditions</h2>
      <ul>
        <li><strong>Agreement to Terms:</strong> By using our services and placing an order with Xpress Delivery, you agree to abide by our terms and conditions outlined here. All transactions are subject to the jurisdiction of Indian laws.</li>
        <li><strong>Services Provided:</strong> We offer various shipping services, including Standard Delivery, Express Delivery, and Overnight Delivery. Detailed descriptions and conditions for each service are available on our website.</li>
        <li><strong>User Responsibilities:</strong> Customers must provide accurate and complete information for shipping purposes. Incorrect or incomplete addresses may result in delivery delays or failures, for which Xpress Delivery is not responsible.</li>
        <li><strong>Shipping Rates and Charges:</strong> Shipping rates are calculated based on weight, dimensions, destination, and shipping method. Rates are displayed at checkout before order confirmation.</li>
        <li><strong>Volumetric Weight:</strong> Shipping charges are based on the greater of the actual weight or the volumetric weight. The volumetric weight is calculated using the formula: (Length×Width×Height)/5000. For example, if the dimensions of a package are 50 cm x 40 cm x 30 cm, the volumetric weight is (50×40×30)/5000=12 kg.</li>
        <li><strong>Processing Time:</strong> Orders are processed within 1-2 business days, excluding weekends and holidays.</li>
        <li><strong>Tracking Orders:</strong> A tracking number will be provided via email once the order is shipped. Customers can monitor their delivery status online.</li>
        <li><strong>Delivery Issues:</strong> Xpress Delivery is not responsible for delays due to customs clearance, incorrect addresses, or missed deliveries. The recipient must follow up with the carrier for redelivery or pick-up instructions.</li>
        <li><strong>Customs and Duties:</strong> For international shipments, the recipient is responsible for any customs duties, taxes, or fees imposed by the destination country.</li>
        <li><strong>No Damage Guarantee:</strong> No refunds will be issued for damages unless the shipment is insured. In such cases, claims should be made with the insurance company.</li>
        <li><strong>Returns and Cancellations:</strong> Refer to our Refunds and Cancellations Policy for detailed instructions and conditions.</li>
        <li><strong>Contact Information:</strong> For any inquiries or assistance, contact us at:
          <ul>
            <li>Phone: 8851227177</li>
            <li>Email: support@xpressdelivery.in</li>
          </ul>
        </li>
      </ul>
    </section>
  
    <section class="privacy-policy-section">
      <h2>Privacy Policy</h2>
      <ul>
        <li><strong>Data Collection:</strong> We collect personal information necessary for processing orders and providing our services. This includes names, addresses, contact details, and payment information.</li>
        <li><strong>Data Usage:</strong> Personal information is used solely for fulfilling orders, processing payments, and improving our services. We do not share your data with third parties without your consent.</li>
        <li><strong>Data Security:</strong> We implement strict security measures to protect your personal information from unauthorized access, disclosure, or misuse.</li>
        <li><strong>Cookies:</strong> Our website uses cookies to enhance user experience and gather analytical data. By using our site, you consent to our cookie policy.</li>
        <li><strong>User Rights:</strong> You have the right to access, modify, or delete your personal information stored with us. Contact our customer service for assistance.</li>
      </ul>
    </section>
  
    <section class="payment-terms-section">
      <h2>Payment Terms</h2>
      <ul>
        <li><strong>Accepted Methods:</strong> We accept credit/debit cards, net banking, and digital wallets.</li>
        <li><strong>Payment Requirements:</strong> Full payment is required at the time of purchase to initiate the shipping process. All prices displayed are inclusive of taxes and shipping charges unless otherwise stated.</li>
      </ul>
    </section>
  
    <section class="refunds-cancellations-policy-section">
      <h2>Refunds and Cancellations Policy</h2>
      <ul>
        <li><strong>Refunds:</strong>
          <ul>
            <li>No refunds will be issued once the package has been dispatched for domestic shipments.</li>
            <li>No refunds will be issued once the package has been booked for international shipments.</li>
          </ul>
        </li>
        <li><strong>Cancellations:</strong>
          <ul>
            <li>Cancellations made on the same day as the order will incur a 25% cancellation fee.</li>
            <li>No refunds will be issued for cancellations made after the package has been dispatched or booked for international shipments.</li>
          </ul>
        </li>
      </ul>
    </section>
  
    <section class="shipping-policy-section">
      <h2>Shipping Policy</h2>
      <ul>
        <li><strong>Shipping Services:</strong>
          <ul>
            <li>Standard Delivery: Delivery within 5-7 business days.</li>
            <li>Express Delivery: Delivery within 2-3 business days.</li>
            <li>Overnight Delivery: Delivery by the next business day.</li>
          </ul>
        </li>
        <li><strong>Shipping Rates:</strong> Shipping rates are calculated based on the weight, dimensions, destination, and shipping method selected. The exact shipping cost will be displayed at checkout before you confirm your order.</li>
        <li><strong>Processing Time:</strong> Orders are processed within 1-2 business days from the time the order is placed. Orders placed on weekends or holidays will be processed on the next business day.</li>
        <li><strong>Order Tracking:</strong> Once your order is shipped, you will receive a tracking number via email. You can use this tracking number to monitor the status of your delivery on our website or the carrier's website.</li>
        <li><strong>Shipping Destinations:</strong> We ship to most locations within India and select international destinations. Please note that delivery times and shipping rates may vary for remote or international locations.</li>
        <li><strong>No Damage Guarantee:</strong> At Xpress Delivery, we take utmost care in handling and delivering your packages. However, no refunds will be issued for damages unless the shipment is insured. In such cases, claims should be made with the insurance company.</li>
        <li><strong>Customs and Duties:</strong> For international shipments, the recipient is responsible for any customs duties, taxes, or fees imposed by the destination country. Xpress Delivery is not responsible for any delays caused by customs clearance processes.</li>
        <li><strong>Delivery Issues:</strong>
          <ul>
            <li><strong>Incorrect Address:</strong> Please ensure that the shipping address provided is correct. We are not responsible for deliveries made to incorrect or incomplete addresses.</li>
            <li><strong>Missed Deliveries:</strong> If a delivery attempt is unsuccessful, the carrier may leave a notice with further instructions. It is the recipient's responsibility to follow up with the carrier to arrange for redelivery or pick up from the carrier's facility.</li>
          </ul>
        </li>
        <li><strong>Return Policy:</strong> If you need to return an item, please refer to our return policy for detailed instructions on how to initiate a return. Our customer service team is available to assist you with any questions or concerns.</li>
        <li><strong>Contact Us:</strong> For any questions or further assistance regarding our shipping policy, please contact our customer service team at:
          <ul>
            <li>Phone: 8851227177</li>
            <li>Email: support@xpressdelivery.in</li>
          </ul>
        </li>
      </ul>
    </section>
  
    <section class="disclaimers-section">
      <h2>Disclaimers</h2>
      <ul>
        <li><strong>General Disclaimer:</strong> Xpress Delivery is not liable for any indirect, incidental, or consequential damages arising from the use of our services.</li>
        <li><strong>Shipping Disclaimer:</strong> We do our best to ensure timely deliveries but cannot guarantee delivery times due to unforeseen circumstances beyond our control.</li>
      </ul>
    </section>
  
    <section class="cookie-policy-section">
      <h2>Cookie Policy</h2>
      <ul>
        <li><strong>Use of Cookies:</strong> We use cookies to improve website functionality, analyze site usage, and personalize content. You can control cookie settings through your browser.</li>
        <li><strong>Types of Cookies:</strong> Our site uses session cookies and persistent cookies for various purposes, including user authentication and tracking user preferences.</li>
      </ul>
    </section>
  
    <section class="ondc-disclaimer-section">
      <h2>ONDC Disclaimer</h2>
      <ul>
        <li><strong>ONDC Participation:</strong> Xpress Delivery may participate in the Open Network for Digital Commerce (ONDC). Any transactions made through ONDC are subject to its terms and conditions, separate from our policies.</li>
      </ul>
    </section>
  
    <section class="fraud-disclaimer-section">
      <h2>Fraud Disclaimer</h2>
      <ul>
        <li><strong>Fraud Prevention:</strong> Xpress Delivery employs various measures to detect and prevent fraudulent activities. Suspicious orders may be subject to verification, delay, or cancellation.</li>
        <li><strong>Reporting Fraud:</strong> Customers are encouraged to report any suspected fraud or unauthorized transactions to our customer service immediately.</li>
      </ul>
    </section>
  
    <section class="otp-disclaimer-section">
      <h2>OTP Disclaimer</h2>
      <ul>
        <li><strong>OTP Usage:</strong> For security purposes, an OTP (One-Time Password) may be sent to your registered mobile number or email during transactions.</li>
        <li><strong>OTP Responsibility:</strong> It is the user's responsibility to ensure the confidentiality of the OTP. Xpress Delivery is not liable for any loss or damage arising from unauthorized use of the OTP.</li>
      </ul>
    </section>
  </div>
  